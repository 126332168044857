import React from "react"
import DetailedFile from "../../components/media-output/detailed-file/detailed-file"
import ReactTooltip from "react-tooltip"
const DetailedFileView = ({ location }) => {
  console.log("current location : ", location)

  return (
    <>
      <DetailedFile location={location} />
      <ReactTooltip
        place="bottom"
        type="info"
        effect="solid"
        globalEventOff="click"
      />
    </>
  )
}

export default DetailedFileView
